
import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"

import FilterControlBox from "../controls/filtercontrolbox";
import ControlSelect from "../../controls/select";
import ControlFloatTextbox from "../../controls/floattextbox";
import TableCell from "../controls/tablecell";

import * as tablescroll from "../../../styles/module/tablescroll.module.css"
import * as styles from "../style.module.css"

import imgboxholder from "../../../images/boxholder.png"
import imgnavprev from "../../../images/buttons/nav_prev.png"
import imgnavnext from "../../../images/buttons/nav_next.png"

import imgfilter from "../../../images/buttons/filter.png"
import imgrefresh from "../../../images/buttons/refresh.png"
import imgdownload from "../../../images/buttons/download.png"

const FULLCOLSPANVALUE=1000;
const UNSORTABLELIST=["image"];

const downloader = require("../../../../lib/tools/downloader");
const formatTools = require("../../../../lib/formatTools");
const webappsAPI = require("../../../../lib/requestWebapps");
/*
const fieldList = [
	{"label":"", "dbfield": "", "type": "", "filtertype": ""}
];
*/
const WebappWidgetTable = ({title, mobilerowtitlefield, disabled, token, entity, showRownum, fieldList, filterfieldList, userparam, searchRowClick, customShowField, initsearchstate, showControls, showRefresh, showDownload}) => {
	const [loading, setLoading] = useState(false);
	const [listdata, setListdata] = useState([]);
	const [paginationitems, setPaginationitems] = useState(50);
	const [pageidx, setPageidx] = useState(initsearchstate.hasOwnProperty("pageidx")?initsearchstate.pageidx:0);
	const [totalrecord, setTotalrecord] = useState(0);
	const [hasnextpage, setHasnextpage] = useState(false);
	const [editpageidx, setEditpageidx] = useState(false);
	const [showfilter, setShowfilter] = useState(false);
	const [filters, setFilters] = useState(initsearchstate.hasOwnProperty("filter")?initsearchstate.filter:[]);
	const [orderbyfield, setOrderbyfield] = useState(initsearchstate.hasOwnProperty("orderbyfield")?initsearchstate.orderbyfield:"");
	const [orderbymode, setOrderbymode] = useState(initsearchstate.hasOwnProperty("orderbymode")?initsearchstate.orderbymode:"desc");


	const loadAPIParam = useCallback( () => {
		var apiparam = JSON.parse(JSON.stringify(userparam));
		if (apiparam.hasOwnProperty("sortlist")) {
			delete apiparam.sortlist;
		}
/*
		const params = {
			*tablelist: [],
			fieldlist: [],
			*joinlist; [{table:"", condlist:[], *type:""}],
			*filters:[[{field:"", value:"", *operation:""}]],
			*condlist: [],
			*grouping: {fieldlist:[], condlist:[]}
			*sortlist: [],

			*getallrows: false,
			*count: 20,
			*offset: 0
	}
*/
		if (filters.length > 0) {
			var tmpstr = "";
			var idx = 0;
			var tmpfilterlist = [];
			var tmpgroupcondlist = [];
			while (idx < filters.length) {
				tmpstr = "";
				if (filters[idx].hasOwnProperty("formula")) {
					if (null !== filters[idx].formula) {
						tmpstr = filters[idx].formula;
					}
				}
				if (tmpstr.length < 1) {
					tmpfilterlist.push ({
						field: filters[idx].field,
						operation: filters[idx].operation,
						value: filters[idx].value
					});
				} else {
					tmpstr = formatTools.trimstr(filters[idx].operation).replace(/'/g, "").toLowerCase();
					if (tmpstr === "in") {
						tmpstr = tmpstr+ " " + filters[idx].value;
					} else {
						tmpstr = tmpstr + " '"+ (filters[idx].value+"").replace(/\'/g,"''") +"'";
					}

					tmpgroupcondlist.push (filters[idx].formula+ " " + tmpstr);
				}
				idx++;
			}

			if (tmpfilterlist.length > 0) {
				if (apiparam.hasOwnProperty("filters")) {
					apiparam.filters[0] = apiparam.filters[0].concat(tmpfilterlist);
				} else {
					apiparam.filters = [tmpfilterlist];
				}
			}
			if (apiparam.hasOwnProperty("grouping") && tmpgroupcondlist.length > 0) {
				if (apiparam.grouping.hasOwnProperty("condlist")) {
					apiparam.grouping.condlist = apiparam.grouping.condlist.concat(tmpgroupcondlist);
				} else {
					apiparam.grouping.condlist = tmpgroupcondlist;
				}

			}
		}
		return apiparam;
	}, [filters, userparam]);

	const loadQueryParam = useCallback( () => {
		var apiparam = loadAPIParam();
		var orderbycond = "";
		if (orderbyfield.length > 0) {
			orderbycond = orderbyfield;
			if (orderbymode.length > 0) {
				orderbycond = orderbycond + " " + orderbymode;
			}
			apiparam.sortlist = [orderbycond];
		} else if (userparam.hasOwnProperty("sortlist")) {
			if (userparam.orderby.length > 0) {
				const desccheck = userparam.orderby.indexOf(" ");
				if (desccheck > 0) {
					setOrderbyfield(userparam.orderby.substring(0, desccheck));
					setOrderbymode(userparam.orderby.substring(desccheck+1));
				} else {
					setOrderbyfield(userparam.orderby);
					setOrderbymode("");
				}
				// Will rerun because of change
				return;
			}
		} else {
			var tmpfieldidx = 0;
			while (tmpfieldidx < fieldList.length) {
				if (!UNSORTABLELIST.includes(fieldList[tmpfieldidx].type)) {
					setOrderbyfield(fieldList[tmpfieldidx].dbfield);
					setOrderbymode("");
					return;
				}
				tmpfieldidx++;
			}
		}

		apiparam.fieldlist = loadDBFieldlist();
		return apiparam;
	}, [loadAPIParam, orderbyfield, orderbymode, UNSORTABLELIST, fieldList, userparam]);

	const loadDBFieldlist = useCallback( () => {
		var dbfieldlist = [];
		var idx = 0;
		while (idx < fieldList.length) {
			if (fieldList[idx].hasOwnProperty("formula")) {
				dbfieldlist.push(fieldList[idx].formula+" as "+fieldList[idx].dbfield);

			} else {
				dbfieldlist.push(fieldList[idx].dbfield);
			}
			idx++;
		}
		return dbfieldlist;
	}, [fieldList]);

	const loadTotalCount = useCallback( (e) => {
		if (e) {
			e.preventDefault();
		}
		if (token !== "") {
			var userecordcount = false;
			var countfield = "";
			var apiparam = loadAPIParam();
			if (apiparam.hasOwnProperty("grouping")) {
				if (apiparam.grouping.hasOwnProperty("fieldlist")) {
					countfield = "DISTINCT "+apiparam.grouping.fieldlist[0];
				}
				if (!apiparam.grouping.hasOwnProperty("condlist")) {
					delete apiparam.grouping;
				} else {
					if (apiparam.grouping.condlist.length < 1) {
						delete apiparam.grouping;
					} else {
						// Output will be grouped
						userecordcount = true;
					}
				}
			}
			if (countfield.length < 1) {
				if (!apiparam.hasOwnProperty("fieldlist")) {
					countfield = entity.toUpperCase()+"."+entity.toLowerCase()+"_id";
				}
			}
			if (countfield.length < 1) {
				return;
			}
			apiparam.fieldlist = ["count("+countfield+") as totalcount"];
			apiparam.getallrows = true;
			webappsAPI.queryData(entity, apiparam, token).then(response => {
				if (response.status === "OK") {
					if (response.records.length > 0) {
						const tmpcount = userecordcount? response.count:response.records[0].totalcount;
						setTotalrecord(tmpcount);
						const maxpage = Math.ceil(tmpcount/paginationitems);
						if (pageidx > maxpage) {
							setPageidx(maxpage-1);
						}
					}
				}
			});
		}
	}, [token, entity, paginationitems, pageidx, loadAPIParam])

	const loadList = useCallback( (e) => {
		if (e) {
			e.preventDefault();
		}
		if (token !== "") {
			setLoading(true);
			var apiparam = loadQueryParam();

			apiparam.offset = pageidx*paginationitems;
			apiparam.count = paginationitems;

			webappsAPI.queryData(entity, apiparam, token).then(response => {
				if (response.status === "OK") {
					setListdata(response.records);
					setHasnextpage(response.hasmore);
				} else {
					setHasnextpage(false);
					setPageidx(0);
					setListdata([]);
					setTotalrecord(0);
					//setDumpdata(JSON.stringify(response));
				}
				setLoading(false);
			});
		}
	}, [token, fieldList, entity, pageidx, paginationitems, orderbyfield, orderbymode, userparam, loadAPIParam, loadDBFieldlist, loadQueryParam])

	useEffect(() => {
		if (token !== "") {
			loadList(null);
		}
	}, [token, loadList])


	useEffect(() => {
		if (token !== "") {
			loadTotalCount(null);
		}
	}, [token, loadTotalCount])

	function updatePageidx(newvalstr)
	{
		var newval = parseInt(newvalstr, 10);
		if (!isNaN(newval)) {
			const maxpage = Math.ceil(totalrecord/paginationitems);
			if (newval < 1) {
				newval = 1;
			} else if (newval > maxpage) {
				newval = maxpage;
			}
			setPageidx(newval-1);
		}
		setEditpageidx(false);
	}

	function updatePaginationitems(newvalstr)
	{
		const newval = parseInt(newvalstr, 10);
		const newnumpage = Math.ceil(totalrecord/newval);
		if (newnumpage <= pageidx) {
			setPageidx(newnumpage-1);
		}
		setPaginationitems(newval);
	}

	function getFieldStr(field, rowdata)
	{
		const fieldvalue = (rowdata.hasOwnProperty(field.dbfield)?rowdata[field.dbfield]:"");
		if (field.type === "custom" && typeof customShowField !== "undefined") {
			const elemobj = customShowField(field, 0, rowdata, styles);
			if (elemobj.hasOwnProperty("props")) {
				if (elemobj.props.hasOwnProperty("children")) {
					return elemobj.props.children;
				}
			}
			return "";
		}

		return downloader.getOutputString(fieldvalue, field.type);
	}

	function downloadData()
	{
		setLoading(true);

		var apiparam = loadQueryParam();

		apiparam.getallrows = true;

		webappsAPI.queryData(entity, apiparam, token).then(response => {
			if (response.status === "OK") {
				var csvstr = "";
				var delimiter = "";
				var tmpstr = "";
				var idx = 0;
				var rowidx = 0;

				// Headers
				while (idx < fieldList.length) {
					if (fieldList[idx].type !== "hidden") {
						csvstr = csvstr + delimiter + downloader.fixCSVString(fieldList[idx].label);
						delimiter = ",";
					}
					idx++;
				}
				csvstr = csvstr + "\n";

				rowidx = 0;
				while (rowidx < response.records.length) {
					delimiter = "";
					idx = 0;
					while (idx < fieldList.length) {
						tmpstr = getFieldStr(fieldList[idx], response.records[rowidx]);
						if (tmpstr !== null) {
							csvstr = csvstr + delimiter + tmpstr;
							delimiter = ",";
						}
						idx++;
					}
					csvstr = csvstr + "\n";
					rowidx++;
				}
				downloader.stringData(title, csvstr);
			}
			setLoading(false);
		});
	}

	function refreshData()
	{
		loadList(null);
		loadTotalCount(null);
	}

	function customSetOrderBy(newfield, newmode)
	{
		setOrderbyfield(newfield);
		setOrderbymode(newmode);
	}
	function customSetFilter(newfilter)
	{
		setFilters(newfilter);
	}

	function toggleSeachfield(e,fieldidx)
	{
		if (e) {
			e.preventDefault();
		}
		if (orderbyfield === fieldList[fieldidx].dbfield) {
			if (orderbymode === "") {
				setOrderbymode("desc");
			} else {
				setOrderbymode("");
			}
		} else if (!UNSORTABLELIST.includes(fieldList[fieldidx].type)) {
			setOrderbyfield(fieldList[fieldidx].dbfield);
			// TODO: UI Option?  For now hardcode default toggle of date to desc
			if (fieldList[fieldidx].type === "date" || fieldList[fieldidx].type === "datetime") {
				setOrderbymode("desc");
			} else {
				setOrderbymode("");
			}
		}

	}

	function defaultSearchHeaderGenerator()
	{
		if (fieldList.length > 0) {

			return fieldList.map((field, idx)=> {
				if (field.type==="hidden") return <></>
				return <td className={field.type==="image"?"":styles.searchtablesortheader} key={entity+"_searchheader_"+idx} onKeyDown={(e)=>{/* Prevents warning */}} onClick={(e)=>{toggleSeachfield(e, idx)}}>{field.label}{
					field.dbfield===orderbyfield?<>
						{orderbymode===""?<>&uarr;</>: <>&darr;</>}
					</>
					: <></>
				}</td>
			});
		}
		return <></>
	}

	function buildSearchState()
	{
		return {
			"pageidx": pageidx,
			"filter": filters,
			"orderbyfield": orderbyfield,
			"orderbymode": orderbymode,
		};
	}

	function defaultSearchRowClick(e, rowdata)
	{
		if (typeof searchRowClick !== "undefined") {
			searchRowClick(e, rowdata, buildSearchState());
		}
	}

	function getMobileTitleRow(entry, idx)
	{
		var output = "";
		if (mobilerowtitlefield.length) {
			var titleidx = 0;
			var fieldidx = 0;
			var tmpstr = "";
			var fieldvalue = "";
			var field = {};

			while (titleidx < mobilerowtitlefield.length) {
				tmpstr = "";
				fieldvalue = entry[mobilerowtitlefield[titleidx]];
				fieldidx = 0;
				field = {};
				while (fieldidx < fieldList.length) {
					if (fieldList[fieldidx].dbfield === mobilerowtitlefield[titleidx]) {
						field = fieldList[fieldidx];
						break;
					}
					fieldidx++;
				}

				if (field.hasOwnProperty("type")) {
					if (field.type === "checkbox") {
						tmpstr = fieldvalue!==0?"Y":"N";
					} else if (field.type === "image") {
						tmpstr = "";
					} else if (field.type === "integer" || field.type === "numeric" || field.type === "currency") {
						if (field.type==="integer")
							tmpstr = formatTools.integerstr(fieldvalue);
						else if (field.type ==="currency")
							tmpstr = formatTools.currencystr(fieldvalue);
						else
							tmpstr = formatTools.numericstr(fieldvalue, 4);
					} else if (field.type === "date" || field.type === "datetime") {
						if (field.type==="date")
							tmpstr = formatTools.datestr(fieldvalue);
						else
							tmpstr = formatTools.datestr(fieldvalue) + " " +formatTools.timestr(fieldvalue);
					} else if (field.type==="hidden") {
						tmpstr = "";
					} else {
						tmpstr = formatTools.utf8str(fieldvalue);
					}
				} // type
				if (tmpstr.length > 0) {
					output = output + " " + tmpstr;
				}
				titleidx++;
			}

		}
		if (output.length < 1) {
			output = (idx+pageidx*paginationitems)+1+")";
		} else {
			output = output.substring(1);
		}
		return output;
	}

	function defaultSearchRowGenerator(rowdata, rowidx)
	{
		if (fieldList.length > 0) {
			const labelclass=styles.searchtablecelllabel+" font-style-italic font-style-bold font-size-small";
			return fieldList.map((field, idx)=> {
				const keyname = entity + "searchrow"+rowidx+"col"+idx;
				var tdclass = "";
				var titleidx = 0;
				while (titleidx < mobilerowtitlefield.length) {
					if (mobilerowtitlefield[titleidx] === field.dbfield) {
						tdclass = styles.searchtablecellhiddenmobiletitle+" ";
						break;
					}
					titleidx++;
				}


				if (field.type === "custom" && typeof customShowField !== "undefined") {
					return <td key={keyname} className={tdclass+"text-centered"}>
							<span className={labelclass}>{field.label+":"}</span>
							{
								customShowField(field, idx, rowdata, styles)
							}
						</td>
				}
				return <TableCell
						tdclass={tdclass}
						keyname={keyname}

						labelclass={labelclass}
						cellobj={field}
						colidx={idx}
						rowidx={rowidx}
						mobilerowtitlefield={mobilerowtitlefield}
						rowdata={rowdata}
						entity={entity}
						token={token}
					/>
			});
		}
		return <></>
	}

	return <>
		<div className={styles.searchheadercontrol+" text-centered"}>
			{title.length > 0 && <h2 className={styles.searchtabletitle}>
				{title}
				{(showControls === false && showRefresh) && <button
						className={"iconbutton iconbutton-medium"}
						disabled={disabled || loading}
						onClick={(e)=>{refreshData()}}
						title={"Refresh"}
					>
						<img src={imgrefresh} alt="Refresh" />
					</button>}
			</h2>}
			{showControls && <>
				<div>
					<button
						className={"iconbutton iconbutton-medium"}
						disabled={disabled || loading}
						onClick={(e)=>{setShowfilter(true)}}
						title={"Filter"}
					>
						<img src={imgfilter} alt="Filter" />
					</button>
					{showDownload && <>
						&nbsp;
						&nbsp;
						&nbsp;
						&nbsp;
						<button
							className={"iconbutton iconbutton-medium"}
							disabled={disabled || loading}
							onClick={(e)=>{downloadData()}}
							title={"Download"}
						>
							<img src={imgdownload} alt="Download" />
						</button>
					</>}
					{showRefresh && <>
						&nbsp;
						&nbsp;
						&nbsp;
						&nbsp;
						<button
							className={"iconbutton iconbutton-medium"}
							disabled={disabled || loading}
							onClick={(e)=>{refreshData()}}
							title={"Refresh"}
						>
							<img src={imgrefresh} alt="Refresh" />
						</button>
					</>}
				</div>
				<FilterControlBox
						showfilter={showfilter}
						totalrecord={totalrecord}
						disabled={disabled || loading}
						filterfieldList={filterfieldList}
						filters={filters}
						orderbyfield={orderbyfield}
						orderbymode={orderbymode}
						customSetShowFilter={setShowfilter}
						customSetFilter={customSetFilter}
						customSetOrderBy={customSetOrderBy}
					/>
			</>}
		</div>
		<div className={tablescroll.headerfooter + " "+styles.searchtableholder}>
			<table className={styles.searchtable}>
				{loading?
					<tbody>
						<tr>
							<td colSpan={FULLCOLSPANVALUE} style={{borderBottom:"none"}}>
								<div className="text-centered font-size-medium">
									Loading...
								</div>
							</td>
						</tr>
					</tbody>
				:<>
					<thead>
						<tr className={styles.searchtableheader}>
							{showRownum && <td>#</td>}
							{defaultSearchHeaderGenerator()}
						</tr>
					</thead>
					<tbody>{listdata.length < 1?
						<tr className={styles.searchtablerow} key={entity+"listnotfound"}>
							<td colSpan={FULLCOLSPANVALUE} className={"text-centered font-size-medium"}>No Records Found</td>
						</tr>
					:<>
						{listdata.map((entry, idx)=> {
							return <tr className={typeof searchRowClick !== "undefined"?styles.searchtablerowclickable+" "+styles.searchtablerow:styles.searchtablerow} key={entity+"list"+idx} onClick={(e)=>{e.preventDefault(); defaultSearchRowClick(e,entry)}}>
								{showRownum&&<td className={styles.searchtablecellright+" "+styles.searchtablerownum}>
									<span className="fullblock-desktop-only">
										{idx+pageidx*paginationitems+1}
									</span>
									<span className="fullblock-mobile-only">
										{getMobileTitleRow(entry, idx)}
									</span>
								</td>}
								{defaultSearchRowGenerator(entry, idx)}
							</tr>
						})}
					</>}</tbody>
					{(20<totalrecord) &&<tfoot>
						<tr>
							<td colSpan={FULLCOLSPANVALUE} className={styles.searchtablefooter}>
								<div className={styles.searchtablefooter}>
									<div className={styles.searchtablesubcontrol} >
										<ControlSelect
												list={[
													{key:"searchtable"+20, code:"20", name:"20 / Page"},
													{key:"searchtable"+50, code:"50", name:"50 / Page"},
													{key:"searchtable"+100, code:"100", name:"100 / Page"}
												]}
												defaultvalue={""+paginationitems}
												handleChange={updatePaginationitems}
												disabled={disabled || loading}
											/>
										&nbsp;
										&nbsp;
									</div>
									<button
										title={"Previous"}
										className={"iconbutton iconbutton-medium"}
										disabled={disabled || pageidx===0 || loading}
										onClick={(e)=>{setPageidx(pageidx-1)}}
									>
										<img src={listdata.length>0? imgnavprev : imgboxholder} alt="<" />
									</button>
									{listdata.length > 0?<div className={styles.searchtablepageinfo}>
										Page
										<button
											title={"Go to Page #"}
											className={"textbutton"}
											disabled={disabled || loading}
											onClick={(e)=>{setEditpageidx(true)}}
										>
											{pageidx+1}
										</button>
										{editpageidx &&
											<ControlFloatTextbox
												disabled={loading}
												displaytext={"Set Page #"}
												defaultvalue={(pageidx+1)+""}
												handleCancel={()=>{setEditpageidx(false)}}
												handleChange={updatePageidx}
											/>
										}
										of {Math.ceil(totalrecord/paginationitems)}
									</div>:<>
										{loading?
											<>Loading...</>
										:
											<>No records found</>
										}
									</>}
									<button
										title={"Next"}
										className={"iconbutton iconbutton-medium"}
										disabled={disabled || hasnextpage===false || loading}
										onClick={(e)=>{setPageidx(pageidx+1)}}
									>
										<img src={listdata.length>0? imgnavnext : imgboxholder} alt=">" />
									</button>
								</div>
							</td>
						</tr>
					</tfoot>}
				</>}
			</table>
		</div>
		<div className="font-size-medium">&nbsp;</div>
	</>
}


WebappWidgetTable.propTypes = {
	initsearchstate: PropTypes.object,
	title: PropTypes.string,
	mobilerowtitlefield: PropTypes.array,
	showRownum: PropTypes.bool,
	showDownload: PropTypes.bool,
	showRefresh: PropTypes.bool,
	showControls: PropTypes.bool,
	fieldList: PropTypes.array,
	filterfieldList: PropTypes.array
};

WebappWidgetTable.defaultProps = {
	title: "",
	mobilerowtitlefield: [],
	initsearchstate: {},
	disabled: false,
	showRownum: true,
	showDownload: false,
	showRefresh: true,
	showControls: true,
	fieldList: [],
	filterfieldList: []
};


export default WebappWidgetTable;
